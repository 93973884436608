import React from "react";
import {Link} from "gatsby";
import { Helmet } from "react-helmet";
import VideoDemo from "../../../sections/common/VideoDemoNew";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/influencers/Hero";
import Main from "../../../sections/influencers/Main";
import Features from "../../../sections/common/Features";
import Wave from "../../../sections/common/Wave";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import imgC from "../../../assets/image/metaimg/influencer.jpg";
const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Interactive Video for Influencers"
          description="Showcase your products with relevant information such as price, size, or type. Let viewers click to buy or add to their shopping cart."
          image={imgC}
        /> 
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=influencers'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=influencers'}></script>
        </Helmet>                      
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/ecommerce">E-commerce</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/ecommerce/influencers">Influencers</Link>
              </li>                            
              </ol>
           </nav>
        </div>             
        <Hero />
        <Wave pos="top" color="#0c426d" />
        <VideoDemo className="bg-digma-dark" title="Influencer Demo" name="20 Summer Dresses" projectId="cbc126b9-92c5-4c01-9884-3Ec4d6c2ef4b" />        
        <Wave pos="bottom" color="#F7F9FC" background="#0c426d" /> 
        <Main className="grey" />
        <Features />
        <CTA className="bg-images" />

      </PageWrapper>
    </>
  );
};
export default PricingPage;
